import React from 'react';
import img from '../images/blockchain_intro.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">ABOUT US</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">CIPHER MIND: PIONEERING BLOCKCHAIN AND AI SOLUTIONS PROVIDERS</h2>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-justify lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" >
                            
                            <h3 className="text-3xl  text-blue-900 font-bold">INNOVATIVE EXCELLENCE IN AI,  BLOCKCHAIN AND MORE</h3>
                            <div>
                                <p className='my-3 text-xl'>Harness the power of Artificial Intelligence and Blockchain for innovative solutions. Our Mobile App Development team creates user-friendly apps for iOS and Android. Elevate your business with cutting-edge technology services.</p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl '>We excel in Microsoft D365 technical functional support, Website Development, creating captivating, and responsive web experiences, In Networking services ensure seamless connectivity and scalability for your business.</p>
                            </div>
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div> 
                    </div>
                </div>
        </>
    )
}

export default Intro;