import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Define an array of paths for which you want to scroll to the top
    const pathsToScrollToTop = ['/', '/contact', '/get-demo'];

    if (pathsToScrollToTop.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null; // This component doesn't render anything
}

export default ScrollToTop;